import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { convertLangCodeForDB } from '@lib';
import category from '@public/categoryIcon.json';
import categories from '@public/category.json';
import useSettings from '@hooks/useSettings';
import mainCardList from '@public/mainCardList.json';
import { Contents, Layout } from '@components/layout';
import {
  GET_CATEGORY_BANNER_INFO,
  GET_CATEGORY_CHILD_LIST,
  GET_CATEGORY_FILTER,
  GET_CATEGORY_LIST,
  GET_CATEGORY_TYPE,
  GET_GODO_BANNER_INFO,
} from '@api';
import { MainWidget } from '@components/main';
import { Category, PromotionBanner, SlideBanner } from '@components/common';
import Page from '@components/Page';
import { PATH_CATEGORY } from 'src/routes/paths';
import CategoryList from 'src/section/category/CategoryList';
import { PRODUCTS_TYPE } from '@components/common/Products/constants';
import {
  GetCategoryQueryVariables,
  MainProductListV2Document, MainProductListV2QueryVariables,
  ProductCardsWithBrandDocument, ProductCardsWithBrandQueryVariables,
  QueryGetGodoBannerInfoArgs,
  QueryGetTransGodoCategoryChildItemsArgs,
  QueryGetTransGodoCategoryItemsArgs, QuerySyncCategoryArgs,
} from 'src/generated/graphql';
import { captureException, ExtraError } from '@util/sentry';
import { serverSideTranslations } from '@util/i18nUtils';
import { catchableQueryFactory, initializeApollo } from '@lib/apolloClient';
import {
  BANNER_INTERNAL_NAME,
  CATEGORY_CODE,
  GOODS_INTERNAL_NAME,
  mainTopSlideCode,
  MAIN_CATEGORY,
} from '../../src/constants';

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout>{page}</Layout>;
};

export default function Home() {
  // 번역 도구
  const { i18n } = useTranslation('common');
  const { themeMode } = useSettings();
  const router = useRouter();

  const handleCategoryClick = (cateCd: string) => {
    router.push(PATH_CATEGORY.view(cateCd), null, { scroll: true });
  };

  return (
    <Page>
      {themeMode === 'toon' && (
        <SlideBanner bannerCode={mainTopSlideCode[i18n.language]} isBorder={false} skeletonHeight={400} />
      )}
      {themeMode === 'game' && <PromotionBanner height={340} code={CATEGORY_CODE.REALISTIC} />}

      <Contents className={'pt-c2'}>
        {themeMode === 'toon' && (
          <>
            {/* <IconGroup /> */}
            <MainWidget />
          </>
        )}
        {themeMode === 'game' && (
          <>
            <Category
              type={MAIN_CATEGORY.REALISTIC}
              categoryCode={CATEGORY_CODE.REALISTIC}
              onClickCategory={handleCategoryClick}
            />
            <CategoryList
              pageType={PRODUCTS_TYPE.CATEGORY}
              categoryCode={CATEGORY_CODE.REALISTIC}
              isFilter={false}
              isBanner={false}
              isFreeSort={false}
            />
          </>
        )}
      </Contents>
    </Page>
  );
}

export const getStaticProps = async ({ locale }) => {
  const apolloClient = initializeApollo();
  const catchableQuery = catchableQueryFactory(apolloClient);
  let cachedData = { };
  if (locale && locale !== 'acon') {
    try {
      const [typeData, ...promiseResults] = await Promise.all([
        // 게임 - 카테고리 대분류 정보
        catchableQuery<GetCategoryQueryVariables>({
          query: GET_CATEGORY_TYPE,
          variables: {
            godoCateCd: CATEGORY_CODE.REALISTIC,
          },
        }, true),
        // 최상단 슬라이드 배너에 대한 호출
        catchableQuery<QueryGetGodoBannerInfoArgs>({
          query: GET_GODO_BANNER_INFO,
          variables: {
            bannerCode: mainTopSlideCode[locale],
          },
        }, true),
        catchableQuery<QueryGetTransGodoCategoryChildItemsArgs>({
          query: GET_CATEGORY_CHILD_LIST,
          variables: {
            lang: convertLangCodeForDB(locale),
            cateCds: categories.rootCategory,
            // 현재는 cartoon 대분류만 사용 중.
            type: MAIN_CATEGORY.CARTOON,
          },
        }, true),
        // 게임 - 상단 이미지 배너
        catchableQuery<GetCategoryQueryVariables>({
          query: GET_CATEGORY_BANNER_INFO,
          variables: {
            godoCateCd: CATEGORY_CODE.REALISTIC,
          },
        }, true),
        // 중간 카테고리 아이콘 그룹
        catchableQuery<QueryGetTransGodoCategoryItemsArgs>({
          query: GET_CATEGORY_LIST,
          variables: {
            cateCds: category[locale],
            lang: convertLangCodeForDB(locale),
          },
        }, true),
      ]);
      for (let result of promiseResults) {
        if (result instanceof Error) {
          const error: ExtraError = result;
          error.message = 'Handled Error from getStaticProps\nIn promiseAll\n' + error.message;
          error.route = '/';
          captureException(error);
        }
      }

      const type = typeData.data?.getCategory?.type;

      // 중간 배너에 대한 호출
      const promiseArr = [];
      for (const cardInfo of (mainCardList[locale] ?? [])) {
        switch (cardInfo && cardInfo.type ? cardInfo.type : null) {
          case GOODS_INTERNAL_NAME:
            /* eslint-disable no-case-declarations */
            await catchableQuery<MainProductListV2QueryVariables>({
              query: MainProductListV2Document,
              variables: {
                lang: convertLangCodeForDB(locale),
                sno: cardInfo.sno,
              },
            }).then((mainProductsData)=> {
              const mainProducts = mainProductsData.data?.mainProductListV2 || null;
              if (mainProducts && mainProducts?.productList && mainProducts.productList.length > 0) {
                promiseArr.push(
                  catchableQuery<ProductCardsWithBrandQueryVariables>({
                    query: ProductCardsWithBrandDocument,
                    variables: {
                      languageCode: locale,
                      productNos: mainProducts.productList,
                    },
                  }),
                );
              }
            }).catch((error: ExtraError) => {
              error.message = 'Handled Error from getStaticProps\nIn `MainProductListV2Document`, unable to query `ProductCardsWithBrandDocument`\n' + error.message;
              error.route = '/';
              captureException(error);
            });
            break;
          case BANNER_INTERNAL_NAME:
            promiseArr.push(
              catchableQuery<QueryGetGodoBannerInfoArgs>({
                query: GET_GODO_BANNER_INFO,
                variables: {
                  bannerCode: cardInfo.sno,
                },
              }),
            );
            break;
        }
      }

      // 게임 - 카테고리 정보
      promiseArr.push(
        catchableQuery<QuerySyncCategoryArgs>({
          query: GET_CATEGORY_FILTER,
          variables: {
            lang: locale,
            type: type,
          },
        }),
      );

      await Promise.all(promiseArr);
      cachedData = apolloClient.cache.extract();
    } catch (error) {
      error.route = '/';
      captureException(error);
    }
  }

  return {
    props: {
      initialApolloState: cachedData,
      ...(await serverSideTranslations(locale === 'acon' ? 'en' : locale, ['common'])),
    },
    revalidate: 60,
  };
};
