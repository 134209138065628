import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { Banner } from '@components/banner';
import { FlexColumn } from '@components/wrapper';
import { CustomText, Skeleton } from '@components/ui';
import { SORTS, SORT_CODE } from '@components/filter/constants';
import { ProductListSort, useGetCategoryQuery, useProductCardsSummaryQuery } from 'src/generated/graphql';
import { numberWithCommas } from '@lib';
import useProducts from '@hooks/useProducts';
import NoItemsMessage from '@components/common/Products/NoItemsMessage';
import Products from '@components/common/Products/Products';
import ProductFilter from '@components/filter/ProductFilter';
import { getExtensionCount } from '@components/common/Products/action';
import * as mixpanel from '@lib/mixpanel';

type Props = {
  pageType: string;
  categoryCode?: string;
  sort?: typeof SORT_CODE[keyof typeof SORT_CODE];
  isFilter?: boolean;
  isSort?: boolean;
  isFreeSort?: boolean;
  isBanner?: boolean;
  listMiddleBanner?: React.ReactElement;
};

export default function CategoryList({ categoryCode, pageType, sort = null, isFilter = true, isSort = true, isFreeSort = true, isBanner = true, listMiddleBanner = null }: Props) {
  const { t, i18n } = useTranslation('common');
  const {
    data: categoryData,
    loading: categoryLoading,
    error: categoryError,
  } = useGetCategoryQuery({
    variables: { godoCateCd: categoryCode },
    skip: !isBanner,
  });
  const isCategoryLoading = Boolean(categoryLoading || categoryError);
  const category = isBanner && !isCategoryLoading && categoryData.getCategory.trans.find((x) => x.lang === i18n.language);

  const {
    data: summaryData,
    loading: summaryLoading,
    error: summaryError,
  } = useProductCardsSummaryQuery({
    variables: {
      categoryCode: categoryCode,
      sort: sort as ProductListSort,
    },
  });
  const isSummaryLoading = summaryLoading || summaryError;
  const productSummary = summaryData?.productCardsSummary;

  const isLoading = categoryLoading || isSummaryLoading;
  const products = useProducts({
    pageType,
    sort: productSummary?.sort,
    isFreeSort,
    summaryProducts: productSummary?.products,
  });

  useEffect(() => {
    if (products.isLoading) {
      if (categoryCode) {
        mixpanel.event('Product List', {
          type: 'category',
          Extensions: products.selectedExtensions,
          sort: products.selectedSort,
          'category code': categoryCode,
        });
      } else {
        mixpanel.event('Product List', {
          type: 'new',
          Extensions: products.selectedExtensions,
        });
      }
    }
  }, [products.selectedExtensions, products.selectedSort, products.isLoading]);

  useEffect(() => {
    if (categoryCode) products.cache.clearAll();
  }, [categoryCode]);

  return (
    <FlexColumn>
      {isBanner && category?.visibleResultStats && (
        <>
          <Banner color={'#f7f7fc'}>
            <>
              {!isLoading && category?.name && (
                <CustomText size={20} weight={700}>
                  {category.name.replace(/#(.*?)#/g, '')}
                </CustomText>
              )}
              <CustomText size={20} className={'ml-2.5'}>
                {t('category.resultText', {
                  count: numberWithCommas(products.filterCount) as never,
                })}
              </CustomText>
            </>
          </Banner>

          <div className={'mb-10'} />
        </>
      )}

      <div className={'mb-100px'}>
        {/** 필터 영역 */}
        {isFilter && (
          <>
            {!isLoading ? (
              <ProductFilter {...products} sorts={SORTS} isSort={isSort} extensionCount={getExtensionCount(productSummary.products)} totalCount={productSummary.products.length} />
            ) : (
              <Skeleton width={'100%'} height={'40px'} />
            )}

            <div className={'mb-7'} />
          </>
        )}

        {/** 상품 목록 영역 */}
        {!products.isLoading && (products.products || []).length === 0 ? (
          <NoItemsMessage title={t('productItems.noItemTextTop')} description={t('productItems.noItemTextBottom')} />
        ) : (
          (() => {
            if (listMiddleBanner) {
              return (
                <>
                  <Products
                    {...products}
                    products={(products.products || []).slice(0, 9)}
                    isVirtual={false}
                    nextOffset={null}
                    isLoading={products.isLoading && (products.products || []).length === 0}
                  />
                  {listMiddleBanner}
                  <Products {...products} products={(products.products || []).slice(9)} filterCount={products.filterCount - 9} isVirtual={false} />
                </>
              );
            }

            return <Products {...products} />;
          })()
        )}
      </div>
    </FlexColumn>
  );
}
